import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import {
    Layout,
    SEO,
    FullHeightWrapper,
    Question,
    Card,
    NavLink,
    FlexWrapper,
    HalfCard
  } from '../components/elements'
import styled from '@emotion/styled'

const RegionCard = styled(Card)`
    ${tw`w-1/3 px-5 text-lg`}
    ${p => p.empty && `
        color: gray;
    ` }
    &:nth-of-type(even){
        margin-left: auto;
    }
`

const RegionWrapper = styled.div`
  ${tw`flex flex-wrap px-2`}
`


export default function Secteur({pageContext}){
    const {nom, slug, currentId} = pageContext;
    
    const {regions, acteurs} = useStaticQuery(graphql`
        query getregions {
            regions:allContentfulRegions(sort: {order: ASC, fields: order}) {
                nodes {
                    contentful_id
                    slug
                    nom
                }
            }
            acteurs:allContentfulActeur {
                nodes {
                    nom
                    slug
                    produits
                    npaLocalite
                    produits
                    secteur {
                        contentful_id
                    }
                    region{
                        contentful_id
                    }
                }
            }
        }

    `)
 
    const currentSecteursActors = acteurs.nodes.filter(acteur => acteur.secteur.contentful_id === currentId);

    return(
        <FullHeightWrapper>
            <SEO
                title={`${nom} - régions`}
                description={`Liste des régions pour le secteur ${nom}`}
                keywords={[`entreprises`, `équitable`, `bio`, `étique`, nom, `régions`]}
            />
            <NavLink to="/">Retour</NavLink>
            <Question>Quelle région est la plus proche de chez vous ?</Question>
            <FlexWrapper>
                {regions.nodes.map((region, i) => {
                    const actors = currentSecteursActors.filter(acteur => acteur.region.contentful_id === region.contentful_id);
                    
                    if(actors.length > 0)
                        return(
                            <HalfCard key={region.contentful_id}>
                                <Link to={`/${slug}/${region.slug}`}>
                                    {region.nom} ({actors.length})
                                </Link>
                            </HalfCard>
                        )
                    else
                        return(
                            <HalfCard key={region.contentful_id} empty>
                                <a href="#">{region.nom} ({actors.length})</a>
                            </HalfCard>
                        ) 
                })}
            </FlexWrapper>
        </FullHeightWrapper>
    )
}